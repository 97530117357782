<template>
  <div class="container-xxl">
    <div id="NotFound" class="p-5">
      <h2 class="display-4">Error 404</h2>
      <div class="bd-callout bd-callout-danger mb-4">
        <span class="alert-icon"><span class="sr-only">Danger</span></span>
        <div>
          <h4 class="alert-heading">Page Not Found</h4>
          <p>We're sorry, the page you requested could not be found. Please go back to the <router-link to="/">homepage</router-link> or <a href="mailto:jerome.giraud@orange.com">contact us</a>.</p>
        </div>
      </div>
    </div>
  </div>
</template>
