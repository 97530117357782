<template>
  <main class="bd-content order-1" id="content" role="main">
        <router-view></router-view>
    </main>
</template>
<script>
    export default {
      name: "ContentView",
    }
</script>
