const rolesPlugin = {
  install(app, store) {
    app.config.globalProperties.$isAdmin = () => {
      for ( let i in store.getters.user.roles ){
        if ( store.getters.user.roles[i] === 'ADMIN'){
          return true;
        }
      }
      return false;
    }
  }
}

export default rolesPlugin;
